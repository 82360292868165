// Main Body SECTION
const mainBody = {
  gradientColors: "#4484ce, #1ad7c0, #ff9b11, #9b59b6, #ff7f7f, #ecf0f1",
  firstName: "Holt",
  middleName: "",
  lastName: "Herndon",
  message: "I like to eat baked goods and to write code.",
  icons: [
    {
      image: "fa-blogger",
      url: "https://blog.holtherndon.com/",
    },
    {
      image: "fa-linkedin",
      url: "https://www.linkedin.com/in/holtherndon/",
    },
  ],
};

export { mainBody, };
