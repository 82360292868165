import React from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import {
  mainBody,
} from "./editable-stuff/config.js";
import MainBody from "./components/home/MainBody";

const Home = () => {
  return (
    <>
      <MainBody
        gradient={mainBody.gradientColors}
        title={`${mainBody.firstName} ${mainBody.middleName} ${mainBody.lastName}`}
        message={mainBody.message}
        icons={mainBody.icons}
      />
    </>
  )
};

const App = () => {
  return (
    <BrowserRouter basename={process.env.PUBLIC_URL + "/"}>
      <Routes>
        <Route path="/" exact element={<Home />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
