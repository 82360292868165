import React from "react";
import Container from "react-bootstrap/Container";
import {Jumbotron} from "./migration";

const MainBody = React.forwardRef(
  ({gradient, title, message, icons}, ref) => {
    return (
      <>
        <div id="stars" />
        <Jumbotron
          fluid
          id="home"
          style={{
            background: `linear-gradient(136deg,${gradient})`,
            backgroundSize: "1200% 1200%",
          }}
          className="title bg-transparent bgstyle text-light min-vh-100 d-flex align-content-center align-items-center flex-wrap m-0"
        >
          <Container className="text-center">
            <h1 ref={ref} className="display-1">
              {title}
            </h1>
            <div className="lead typist">
              {message}
            </div>
            <div className="p-5">
              {icons.map((icon, index) => (
                <a
                  key={`social-icon-${index}`}
                  target="_self"
                  rel="noopener noreferrer"
                  href={icon.url}
                  aria-label={`My ${icon.image.split("-")[1]}`}
                >
                  <i className={`fab ${icon.image}  fa-3x socialicons`}/>
                </a>
              ))}
            </div>
          </Container>
        </Jumbotron>
      </>
    );
  }
);

export default MainBody;
